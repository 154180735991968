<template>
  <div class="q-mt-lg bg-grey-4">
    <q-layout view="lHh Lpr lff">
      <q-header bordered class="bg-white">
        <q-toolbar class="q-pr-lg row text-cs-black">
          <div class="row justify-center width-fit-content-flex">
            <q-item>
              <q-img
                fit="contain"
                :width="$q.platform.is.mobile ? '60px' : '100px'"
                :src="logo"
              />
            </q-item>
          </div>
          <div class="q-gutter-sm row items-center no-wrap">
            <template v-if="isFlipnetCompany || getSwitchCompanies()">
              <list-my-companies />
              <q-separator vertical />
            </template>
            <card-user-block />
          </div>
        </q-toolbar>
      </q-header>
      <q-page-container>
        <q-page padding :style-fn="myTweak">
          <router-view></router-view>
        </q-page>
        <footer-component />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import { getSwitchCompanies } from "@/shared/helpers/switchCompaniesHelper";
import { createNamespacedHelpers, useActions } from "vuex-composition-helpers";
import FooterComponent from "../components/FooterComponent.vue";
import ListMyCompanies from "../views/list-my-companies";
import cardUserBlock from "../views/card-user-blocked";
import { onMounted } from "vue";

const partnerNamespace = createNamespacedHelpers("partner");

export default {
  name: "BlockLayout",

  components: { ListMyCompanies, cardUserBlock, FooterComponent },

  setup() {
    document.body.classList.add("theme-light-dark");

    const { partner, logo, isFlipnetCompany } = partnerNamespace.useGetters({
      partner: "getPartner",
      logo: "getPartnerParentLogo",
      isFlipnetCompany: "isFlipnetCompany",
    });

    const { fetchOverview } = useActions({
      fetchOverview: "home/fetchOverview",
    });

    onMounted(() => {
      fetchOverview({
        ["filter[fields]"]: getSwitchCompanies()
          ? "listCompaniesPartnerRegistered"
          : "",
      });
    });

    return {
      logo,
      partner,
      getSwitchCompanies,
      isFlipnetCompany,
      myTweak: () => ({ minHeight: "65vh" }),
    };
  },
};
</script>
